.title {
  font-family: "Parisienne", cursive;
  font-weight: 400;
  font-style: normal;
}

/* Set height to 100% for body and html to enable the background image to cover the whole page: */
body, html {
  height: 100%
}

.bgimg {
  /* Background image */
  background-image: url('./background-image.jpg');
  /* Full-screen */
  height: 100%;
  /* Center the background image */
  background-position: center;
  /* Scale and zoom in the image */
  background-size: cover;
  /* Add position: relative to enable absolutely positioned elements inside the image (place text) */
  position: relative;

  color: white;
}

/* Position text in the middle */
.coming-soon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(0, 0, 0, .6);
  padding: 1vw;
}

/* Style the <hr> element */
hr {
  margin: auto;
  width: 40%;
}
